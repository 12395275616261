<template>
  <v-card :value="value" class="d-flex flex-grow-1 flex-column">
    <section class="pa-4">
        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <alterar-senha-form v-model="item"/>
        </v-form>
    </section>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="success" @click="irProPainel">Cancelar</v-btn>
      <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {update} from "../../api/alterarSenha";
import AlterarSenhaForm from "../../components/shared/forms/AlterarSenhaForm";
import {ApiError} from "../../api/core";

export default {
  name: "index",
  components: { AlterarSenhaForm },
  props: ['value'],
  data () {
    return {
      item: {},
      formValid: false
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          await update(this.item);
          
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Senha alterada com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível alterar a Senha.`, {
            timeout: 3000
          })
        }        
      }
    },
    irProPainel() {
      this.$router.push({ name: 'Painel' });
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>